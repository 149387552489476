const pcHome = () => import('@/views/pc/home/index.vue'); //首页
const pcKnowAy = () => import('@/views/pc/knowAy/index.vue'); //了解爱跃
const pcCourseService = () => import('@/views/pc/courseService/index.vue'); //课程服务
const pcSecurity = () => import('@/views/pc/security/index.vue'); //防伪鉴别

export default [
  {
    path: '/pc/home',
    component: pcHome,
    name: 'pcHome'
  },
  {
    path: '/pc/pcKnowAy',
    component: pcKnowAy,
    name: 'pcKnowAy'
  },
  {
    path: '/pc/pcCourseService',
    component: pcCourseService,
    name: 'pcCourseService'
  },
  {
    path: '/pc/pcSecurity',
    component: pcSecurity,
    name: 'pcSecurity'
  }
];
