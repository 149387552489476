const mobileHome = () => import('@/views/mobile/home/index.vue'); //首页
const mobileNavMenu = () => import('@/views/mobile/navMenu/index.vue'); //导航菜单
const mobileKnowAy = () => import('@/views/mobile/knowAy/index.vue'); //了解爱跃
const mobileCourseService = () => import('@/views/mobile/courseService/index.vue'); //课程服务
const mobileSecurity = () => import('@/views/mobile/security/index.vue'); //防伪鉴别
const demo = () => import('@/views/mobile/demo/index.vue'); //demo

export default [
  {
    path: '/mobile/home',
    component: mobileHome,
    name: 'mobileHome'
  },
  {
    path: '/mobile/navMenu',
    component: mobileNavMenu,
    name: 'mobileNavMenu'
  },
  {
    path: '/mobile/courseService',
    component: mobileCourseService,
    name: 'mobileCourseService'
  },
  {
    path: '/mobile/knowAy',
    component: mobileKnowAy,
    name: 'mobileKnowAy'
  },
  {
    path: '/mobile/security',
    component: mobileSecurity,
    name: 'mobileSecurity'
  },
  {
    path: '/mobile/demo',
    component: demo,
    name: 'demo'
  }
];
